import poolsConfig from 'config/constants/pools'
import sousChefABI from 'config/abi/sousChef.json'
import bankABI from 'config/abi/stacking.json'
import erc20ABI from 'config/abi/erc20.json'
import multicall, { multicallv3 } from 'utils/multicall'
import { getAddress, getMulticallAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import uniq from 'lodash/uniq'
import fromPairs from 'lodash/fromPairs'
import multiCallAbi from 'config/abi/Multicall.json'
import { retry } from "../multicall/retry";

// Pool 0, Cake / Cake is a different kind of contract (master chef)
// BNB pools use the native BNB token (wrapping ? unwrapping is done at the contract level)
const nonBnbPools = poolsConfig.filter((pool) => pool.stakingToken.symbol !== 'BNB')
const bnbPools = poolsConfig.filter((pool) => pool.stakingToken.symbol === 'BNB')
const nonMasterPools = poolsConfig.filter((pool) => pool.sousId !== 0)
// BANK
const nonEthPools = poolsConfig.filter((pool) => pool.stakingToken.symbol !== 'ETH'&&pool.stakingToken.symbol !== 'GOR')
const ethPools = poolsConfig.filter((pool) => pool.stakingToken.symbol === 'ETH'||pool.stakingToken.symbol === 'GOR')

const multicallAddress = getMulticallAddress()

export const fetchPoolsAllowance = async (account) => {
  const calls = nonBnbPools.map((pool) => ({
    address: pool.stakingToken.address,
    name: 'allowance',
    params: [account, getAddress(pool.contractAddress)],
  }))

  const allowances = await multicall(erc20ABI, calls)
  return fromPairs(nonBnbPools.map((pool, index) => [pool.sousId, new BigNumber(allowances[index]).toJSON()]))
}

export const fetchBanksAllowance = async (account,chainId,isMiracle) => {
  const excutePools = nonEthPools.filter(p=>p.isMiracle === isMiracle && p.contractAddress.hasOwnProperty(chainId))
  const calls = excutePools.map((pool) => {
    return ({
      address: pool.stakingToken.address,
      name: 'allowance',
      params: [account,  getAddress(pool.contractAddress,chainId)],
    })
  })
  const allowances = await multicall(erc20ABI, calls,chainId)
  // const result = fromPairs(excutePools.map((pool, index) =>{
  //   console.log(allowances[index]._hex)
  //   return  [pool.sousId, new BigNumber(allowances[index]._hex).toJSON()]
  // }))
  return fromPairs(excutePools.map((pool, index) => [pool.sousId, new BigNumber(allowances[index]).toJSON()]))
}

export const fetchUserBalances = async (account) => {
  // Non BNB pools
  const tokens = uniq(nonBnbPools.map((pool) => pool.stakingToken.address))
  const tokenBalanceCalls = tokens.map((token) => ({
    abi: erc20ABI,
    address: token,
    name: 'balanceOf',
    params: [account],
  }))
  const bnbBalanceCall = {
    abi: multiCallAbi,
    address: multicallAddress,
    name: 'getEthBalance',
    params: [account],
  }
  const tokenBnbBalancesRaw = await multicallv3({ calls: [...tokenBalanceCalls, bnbBalanceCall] })
  const bnbBalance = tokenBnbBalancesRaw.pop()
  const tokenBalances = fromPairs(tokens.map((token, index) => [token, tokenBnbBalancesRaw[index]]))

  const poolTokenBalances = fromPairs(
    nonBnbPools
      .map((pool) => {
        if (!tokenBalances[pool.stakingToken.address]) return null
        return [pool.sousId, new BigNumber(tokenBalances[pool.stakingToken.address]).toJSON()]
      })
      .filter(Boolean),
  )

  // BNB pools
  const bnbBalanceJson = new BigNumber(bnbBalance.toString()).toJSON()
  const bnbBalances = fromPairs(bnbPools.map((pool) => [pool.sousId, bnbBalanceJson]))
  return { ...poolTokenBalances, ...bnbBalances }
}

export const fetchBankUserBalances = async (account,chainId,isMiracle) => {
  const excutePools = nonEthPools.filter(p=>p.isMiracle === isMiracle && p.contractAddress.hasOwnProperty(chainId))
  // Non ETH pools
  const tokens = uniq(excutePools.map((pool) => pool.stakingToken.address))
  const tokenBalanceCalls = tokens.map((token) => ({
    abi: erc20ABI,
    address: token,
    name: 'balanceOf',
    params: [account],
  }))
  const ethBalanceCall = {
    abi: multiCallAbi,
    address: multicallAddress,
    name: 'getEthBalance',
    params: [account],
  }
  const tokenEthBalancesRaw = await multicallv3({ calls: [...tokenBalanceCalls, ethBalanceCall] })
  const ethBalance = tokenEthBalancesRaw.pop()
  const tokenBalances = fromPairs(tokens.map((token, index) => [token, tokenEthBalancesRaw[index]]))

  const poolTokenBalances = fromPairs(
    excutePools
      .map((pool) => {
        if (!tokenBalances[pool.stakingToken.address]) return null
        return [pool.sousId, new BigNumber(tokenBalances[pool.stakingToken.address]).toJSON()]
      })
      .filter(Boolean),
  )

  // Eth pools
  const ethBalanceJson = new BigNumber(ethBalance.toString()).toJSON()
  const ethBalances = fromPairs(ethPools.filter(p=>p.isMiracle === isMiracle && p.contractAddress.hasOwnProperty(chainId)).map((pool) => [pool.sousId, ethBalanceJson]))
  return { ...poolTokenBalances, ...ethBalances }
}

export const fetchUserStakeBalances = async (account) => {
  const calls = nonMasterPools.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'userInfo',
    params: [account],
  }))
  const userInfo = await multicall(sousChefABI, calls)
  return fromPairs(
    nonMasterPools.map((pool, index) => [pool.sousId, new BigNumber(userInfo[index].amount._hex).toJSON()]),
  )
}

export const fetchUserBankStakeBalances = async (account,chainId,isMiracle) => {
  const excutePools = nonEthPools.filter(p=>p.isMiracle === isMiracle && p.contractAddress.hasOwnProperty(chainId))
  const calls = excutePools.map((p) => ({
    address: getAddress(p.contractAddress,chainId),
    name: 'getStakedAmount',
    params: [account,p.poolIndex],
  }))
  const userInfo = await multicall(bankABI, calls,chainId)
  return fromPairs(
    excutePools.map((pool, index) => {
      return [pool.sousId, new BigNumber(userInfo[index][0]._hex).toJSON()]
    }),
  )
}

export const fetchUserPendingRewards = async (account) => {
  const calls = nonMasterPools.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'pendingReward',
    params: [account],
  }))
  const res = await multicall(sousChefABI, calls)
  return fromPairs(nonMasterPools.map((pool, index) => [pool.sousId, new BigNumber(res[index]).toJSON()]))
}

export const fetchBankUserPendingRewards = async (account,sousIds,chainId,isMiracle) => {
  // bank method 当没有stake会报错，这里需要过滤掉没有质押的pool
  const excutePools = nonEthPools.filter(p=>p.isMiracle === isMiracle && p.contractAddress.hasOwnProperty(chainId) && sousIds.includes(p.sousId.toString()))
  const calls = excutePools.map((p) => ({
    address: getAddress(p.contractAddress,chainId),
    name: 'getClaimableAmount',
    params: [account,p.poolIndex],
  }))
  const res = await multicall(bankABI, calls,chainId)
  return fromPairs(excutePools.map((pool, index) =>{
    return [pool.sousId, new BigNumber(res[index][0]._hex).toJSON()]
  }))
}
