import { Percent } from '@pancakeswap/swap-sdk-core'
import { ERC20Token } from './entities/token'

export enum ChainId {
  ETHEREUM = 1,
  GOERLI = 5,
  BSC = 56,
  BSC_TESTNET = 97,
  ZKSYNC = 324,
  ZKSYNC_TESTNET = 280,
  DEV_ETH = 9,
  DEV_ZK = 270,
  POLYGON_TESTNET = 80001,
  POLYGON = 137,
  FIL_TESTNET = 314159,
  LINEA_TESTNET = 59140,
  LINEA = 591,
  FIL = 314,
  BASE = 8453,
}

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

export const FACTORY_ADDRESS_MAP: Record<number, string> = {
  [ChainId.ETHEREUM]: '0xc8b3A58891F44eA7A6CE8dc6AC186A806d215497',
  [ChainId.GOERLI]: '0x782a90c71cc9028574EC2c03c58EA383F4d7Ccf4',
  [ChainId.ZKSYNC]: '0xAb67DF04C872e54751cfC199c213f53838871279',
  [ChainId.ZKSYNC_TESTNET]: '0x2D83821Ce4a720820a011723851a37Cde20B63ba',
  [ChainId.BSC]: '0xFb21c91a01004a33B547b6602608794d8111Efc4',
  [ChainId.BSC_TESTNET]: '0x6725f303b657a9451d8ba641348b6761a6cc7a17',
  [ChainId.DEV_ETH]: '0x8DBc9e2d139e156260D45b31027f05eA55704463',
  [ChainId.DEV_ZK]: '0xe69807158c4093fd3bfb2733D10181C478b69708',
  [ChainId.POLYGON]: '0x6d2bAcF831cEd9455F6eB16A2a40566B34922Dd9',
  [ChainId.POLYGON_TESTNET]: '0xf7AA93A38B7bDc49De5C67A03Fd33d593392Fc48',
  [ChainId.FIL_TESTNET]: '0x8f3856500c4E0850559132Ee08f4A58b1adA49AF',
  [ChainId.FIL]: '0x1FbA279046914335905e6f140C6c003784826d17',
  [ChainId.LINEA_TESTNET]: '0x680DB0FF56d2c1029AeCc13fa90Af741C29022d0',
  [ChainId.LINEA]: '0x0000000000000000000000000000000000000000',
  [ChainId.BASE]: '0xd3A60C303D647eaF04554599bb8DA5CC18AA3523',
}

export const INIT_CODE_HASH_MAP: Record<number, string> = {
  [ChainId.ETHEREUM]: '0x29b12cc42fbbf63d5ec2e857497df7415e6b2a76f8e23b340a15931dd4b68471',
  [ChainId.GOERLI]: '0xccc8d67ef5bae5ff088ec796cb51be5203c1691ca6d9826859414f2c63f9b6c3',
  [ChainId.ZKSYNC]: '0x1cb011040b91cd937ddff2327f17c9690653b05b6506e830baadf2493468d657',
  [ChainId.ZKSYNC_TESTNET]: '0xe58853225e5265d7245745618558244b1c905bcbf7fb42bf5e2a29ef07fd416f',
  [ChainId.BSC]: '0x29b12cc42fbbf63d5ec2e857497df7415e6b2a76f8e23b340a15931dd4b68471',
  [ChainId.BSC_TESTNET]: '0xd0d4c4cd0848c93cb4fd1f498d7013ee6bfb25783ea21593d5834f5d250ece66',
  [ChainId.DEV_ETH]: '0xb0ffc2dc8f201b98dcc788248362d6ca36d220bc0acd07a656d6fc34d2a264b0',
  [ChainId.DEV_ZK]: '0x1cb011040b91cd937ddff2327f17c9690653b05b6506e830baadf2493468d657',
  [ChainId.POLYGON_TESTNET]: '0xc1d4a7abee6cce609af5280c8ba32bb12777096ce5c39ae7a955b09349ecd145',
  [ChainId.POLYGON]: '0x29b12cc42fbbf63d5ec2e857497df7415e6b2a76f8e23b340a15931dd4b68471',
  [ChainId.FIL_TESTNET]: '0xc1d4a7abee6cce609af5280c8ba32bb12777096ce5c39ae7a955b09349ecd145',
  [ChainId.FIL]: '0x29b12cc42fbbf63d5ec2e857497df7415e6b2a76f8e23b340a15931dd4b68471',
  [ChainId.LINEA_TESTNET]: '0x13ef436d5acd4d1f4ed00f1eeb7f0877f9be64a917fa967658a861a26c2e46ba',
  [ChainId.LINEA]: '0x0000000000000000000000000000000000000000',
  [ChainId.BASE]: '0x29b12cc42fbbf63d5ec2e857497df7415e6b2a76f8e23b340a15931dd4b68471',
}

export const WETH9 = {
  [ChainId.ETHEREUM]: new ERC20Token(
    ChainId.ETHEREUM,
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    18,
    'WETH',
    'Wrapped Ether',
    'https://weth.io'
  ),
  [ChainId.GOERLI]: new ERC20Token(
    ChainId.GOERLI,
    '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    18,
    'WETH',
    'Wrapped Ether',
    'https://weth.io'
  ),
  [ChainId.ZKSYNC]: new ERC20Token(
    ChainId.ZKSYNC,
    '0x5298D95A128851aab5669AD1b96D9203985911C4',
    18,
    'WETH',
    'Wrapped Ether',
    'https://weth.io'
  ),
  [ChainId.ZKSYNC_TESTNET]: new ERC20Token(
    ChainId.ZKSYNC_TESTNET,
    '0x96f8236294502f0d953C6be1758562d9Ff84F882',
    18,
    'WETH',
    'Wrapped Ether',
    'https://weth.io'
  ),
  [ChainId.DEV_ETH]: new ERC20Token(
    ChainId.DEV_ETH,
    '0x8f51A039A642BE20243ca68aC30B13419FBfDBf5',
    18,
    'WETH',
    'Wrapped Ether',
    'https://weth.io'
  ),
  [ChainId.DEV_ZK]: new ERC20Token(
    ChainId.DEV_ZK,
    '0xD40904E622ca88295427e6e5FF4a65C621C7F5dF',
    18,
    'WETH',
    'Wrapped Ether',
    'https://weth.io'
  ),
  [ChainId.POLYGON_TESTNET]: new ERC20Token(
    ChainId.POLYGON_TESTNET,
    '0x9c3c9283d3e44854697cd22d3faa240cfb032889',
    18,
    'WMATIC',
    'Wrapped MATIC',
  ),
  [ChainId.POLYGON]: new ERC20Token(
    ChainId.POLYGON,
    '0x8873d4fdfaEeC983fD33eBa1a504972cb3c73fB3',
    18,
    'WMATIC',
    'Wrapped MATIC',
  ),
  [ChainId.LINEA_TESTNET]: new ERC20Token(
    ChainId.LINEA_TESTNET,
    '0x338076fAA234bB503eB66232DaE66Ca09744C075',
    18,
    'WETH',
    'Wrapped Ether',
    'https://weth.io'
  ),
  [ChainId.FIL_TESTNET]: new ERC20Token(
    ChainId.FIL_TESTNET,
    '0x45C0d8ECD32173Ef304D062e5032FfF1e82A05a6',
    18,
    'WFIL',
    'Wrapped Filecoin',
  ),
  [ChainId.LINEA]: new ERC20Token(
    ChainId.LINEA,
    '0x0000000000000000000000000000000000000000',
    18,
    'WETH',
    'Wrapped Ether',
    'https://weth.io'
  ),
  [ChainId.FIL]: new ERC20Token(
    ChainId.FIL,
    '0x48C0c7f687B15a5978311fAf0a4F183A9FEe3AcC',
    18,
    'WFIL',
    'Wrapped Filecoin',
  ),
  [ChainId.BASE]: new ERC20Token(
    ChainId.BASE,
    '0x545808A5456BaCfe7b7c9694a4B3a8Fc448E7190',
    18,
    'WETH',
    'Wrapped Ether',
  ),
}

export const WBNB = {
  [ChainId.ETHEREUM]: new ERC20Token(
    ChainId.ETHEREUM,
    '0x418D75f65a02b3D53B2418FB8E1fe493759c7605',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.org'
  ),
  [ChainId.BSC]: new ERC20Token(
    ChainId.BSC,
    '0x762d578a5D0Bb41c7656B5CE80131d18862bCEBa',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.org'
  ),
  [ChainId.BSC_TESTNET]: new ERC20Token(
    ChainId.BSC_TESTNET,
    '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.org'
  ),
}

export const WNATIVE: Record<number, ERC20Token> = {
  [ChainId.ETHEREUM]: WETH9[ChainId.ETHEREUM],
  [ChainId.GOERLI]: WETH9[ChainId.GOERLI],
  [ChainId.ZKSYNC_TESTNET]: WETH9[ChainId.ZKSYNC_TESTNET],
  [ChainId.ZKSYNC]: WETH9[ChainId.ZKSYNC],
  [ChainId.BSC]: WBNB[ChainId.BSC],
  [ChainId.BSC_TESTNET]: WBNB[ChainId.BSC_TESTNET],
  [ChainId.DEV_ETH]: WETH9[ChainId.DEV_ETH],
  [ChainId.DEV_ZK]: WETH9[ChainId.DEV_ZK],
  [ChainId.POLYGON_TESTNET]: WETH9[ChainId.POLYGON_TESTNET],
  [ChainId.POLYGON]: WETH9[ChainId.POLYGON],
  [ChainId.LINEA_TESTNET]: WETH9[ChainId.LINEA_TESTNET],
  [ChainId.FIL_TESTNET]: WETH9[ChainId.FIL_TESTNET],
  [ChainId.LINEA]: WETH9[ChainId.LINEA],
  [ChainId.FIL]: WETH9[ChainId.FIL],
  [ChainId.BASE]: WETH9[ChainId.BASE],
}

export const NATIVE: Record<
  number,
  {
    name: string
    symbol: string
    decimals: number
  }
> = {
  [ChainId.ETHEREUM]: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  [ChainId.GOERLI]: { name: 'Goerli Ether', symbol: 'GOR', decimals: 18 },
  [ChainId.ZKSYNC]: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  [ChainId.ZKSYNC_TESTNET]: { name: 'Goerli Ether', symbol: 'GOR', decimals: 18 },
  [ChainId.DEV_ETH]: { name: 'Dev Ether', symbol: 'dETH', decimals: 18 },
  [ChainId.DEV_ZK]: { name: 'Dev ZK Ether', symbol: 'dzETH', decimals: 18 },
  [ChainId.POLYGON_TESTNET]: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
  [ChainId.POLYGON]: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
  [ChainId.BSC]: {
    name: 'Binance Chain Native Token',
    symbol: 'BNB',
    decimals: 18,
  },
  [ChainId.BSC_TESTNET]: {
    name: 'Binance Chain Native Token',
    symbol: 'tBNB',
    decimals: 18,
  },
  [ChainId.FIL_TESTNET]: { name: 'tFIL', symbol: 'FIL', decimals: 18 },
  [ChainId.LINEA_TESTNET]: { name: 'tETH', symbol: 'ETH', decimals: 18 },
  [ChainId.FIL]: { name: 'FIL', symbol: 'FIL', decimals: 18 },
  [ChainId.LINEA]: { name: 'ETH', symbol: 'ETH', decimals: 18 },
  [ChainId.BASE]: { name: 'ETH', symbol: 'ETH', decimals: 18 },
}
