import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  TradeIcon,
  TradeFilledIcon,
  SwapFillIcon, DropdownMenuItems, InfoFilledIcon,
  InfoIcon
} from "@pancakeswap/uikit";
import { ContextApi } from '@pancakeswap/localization';

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label:'Overview',
      icon: TradeIcon,
      fillIcon: TradeFilledIcon,
      href: '/overview',
      hideSubNav: true,
      showItemsOnMobile: false,
      items: []
    },
    {
      label: t('Trade'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/swap'||'liquidity',
      hideSubNav: true,
      items: [
        {
          label: t('Swap'),
          href: '/swap',
        },
        // {
        //   label: t('Limit'),
        //   href: '',
        //   disabled: true,
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
        {
          label: t('Liquidity'),
          href: '/liquidity',
        },
        // {
        //   label: t('Perpetual'),
        //   href: '',
        //   disabled: true,
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
        // {
        //   label: t('Bridge'),
        //   href: 'https://goerli-bridge.base.org/',
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    // {
    //   label: t('Earn'),
    //   href: '/pools'||'/miracle',
    //   icon: EarnIcon,
    //   fillIcon: EarnFillIcon,
    //   image: '/images/decorations/pe2.png',
    //   hideSubNav: true,
    //   items: [
    //     {
    //       label: t('Farms'),
    //       href: '/miracle',
    //       // href: '/?modal=miracle',
    //     },
    //     {
    //       label: t('Pools'),
    //       href: '/pools',
    //       // supportChainIds: SUPPORT_ONLY_BSC,
    //     },
    //     // {
    //     //   label: t('Liquid Staking'),
    //     //   href: '',
    //     //   disabled: true,
    //     //   type: DropdownMenuItemType.EXTERNAL_LINK,
    //     // },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: t('IDO'),
    //   href: '/ido',
    //   icon: CurrencyIcon,
    //   disabled: true,
    //   fillIcon: CurrencyIcon,
    //   hideSubNav: true,
    //   items: [
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
   /* {
      label: t('Win'),
      href: '/prediction',
      icon: TrophyIcon,
      fillIcon: TrophyFillIcon,
      supportChainIds: SUPPORT_ONLY_BSC,
      items: [
        {
          label: t('Trading Competition'),
          href: '/competition',
          // image: '/images/decorations/tc.png',
          // hideSubNav: true,
          disabled: true,
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: t('Prediction'),
          href: '/prediction',
          // image: '/images/decorations/prediction.png',
          disabled: true,
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: t('Lottery'),
          href: '/lottery',
          // image: '/images/decorations/lottery.png',
          disabled: true,
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: t('Pottery'),
          href: '/pottery',
          // image: '/images/decorations/lottery.png',
          disabled: true,
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
      ],
    }, */
    /* {
      label: t('NFT'),
      href: `${nftsBaseUrl}`,
      icon: NftIcon,
      fillIcon: NftFillIcon,
      // supportChainIds: SUPPORT_ONLY_BSC,
      image: '/images/decorations/nft.png',
      items: [
        {
          label: t('Overview'),
          href: `${nftsBaseUrl}`,
          disabled: true,
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: t('Collections'),
          href: `${nftsBaseUrl}/collections`,
          disabled: true,
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: t('Activity'),
          href: `${nftsBaseUrl}/activity`,
          disabled: true,
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
      ],
    }, */
    // {
    //   label: t('Gumball'),
    //   icon: PredictionsIcon,
    //   fillIcon: PredictionsIcon,
    //   href: `${gumballBaseUrl}#/swap`,
    //   image: '/images/decorations/nft.png',
    //   items: [
    //     {
    //       label: t('Exchange'),
    //       href: `${gumballBaseUrl}#/swap`,
    //       target: '_blank',
    //     },
    //     {
    //       label: t('Shop'),
    //       href: `${gumballBaseUrl}#/gachaMachine`,
    //       target: '_blank',
    //     },
    //   ],
    // },
    /* {
      label: t('Game'),
      icon: PoolIcon,
      fillIcon: PoolIcon,
      // href: `${gumballBaseUrl}#/swap`,
      // image: '/images/decorations/nft.png',
      items: [
        {
          label: t('Petrip'),
          href: '',
          disabled: true,
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
      ],
      hideSubNav: true,
    }, */
    {
      label: t('More'),
      href: '',
      icon: InfoIcon,
      fillIcon: InfoFilledIcon,
      hideSubNav: true,
      items: [
        // {
        //   label: t('About Peta'),
        //   href: '/peta',
        // },
        // {
        //   label: t('Roadmap'),
        //   href: '/peta/roadmap',
        // },
        // {
        //   label: t('The Lab'),
        //   href: '/peta/lab',
        // },
        // {
        //   type: DropdownMenuItemType.DIVIDER,
        // },
        // {
        //   label: t('Info'),
        //   href: '/info',
        //   disabled: true,
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
        // {
        //   label: t('IFO'),
        //   href: '/ifo',
        //   supportChainIds: SUPPORT_ONLY_BSC,
        //   image: '/images/ifos/ifo-bunny.png',
        // },
        // {
        //   label: t('Voting'),
        //   href: '/voting',
        //   // supportChainIds: SUPPORT_ONLY_BSC,
        //   // image: '/images/voting/voting-bunny.png',
        //   disabled: true,
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
        // {
        //   type: DropdownMenuItemType.DIVIDER,
        // },
        // {
        //   label: t('Leaderboard'),
        //   href: '/teams',
        //   // supportChainIds: SUPPORT_ONLY_BSC,
        //   // image: '/images/decorations/leaderboard.png',
        //   disabled: true,
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
        // {
        //   type: DropdownMenuItemType.DIVIDER,
        // },
        {
          label: t('Blog'),
          href: 'https://mirror.xyz/0x2b26B4A97C6c12361DC038ebc38184c94a8CcD89',
          disabled: false,
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: t('Whitepaper'),
          href: 'https://aiswap2023s-organization.gitbook.io/aiswap-whitepaper/',
          disabled: false,
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        // {
        //   label: t('METAVERSE GATE'),
        //   href: '/meta',
        //   target: '_blank',
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config