import { ChainId } from '@pancakeswap/sdk'
import memoize from 'lodash/memoize'
import invert from 'lodash/invert'

export const CHAIN_QUERY_NAME = {
  [ChainId.ETHEREUM]: 'eth',
  [ChainId.GOERLI]: 'goerli',
  [ChainId.BSC]: 'bsc',
  [ChainId.BSC_TESTNET]: 'bscTestnet',
  [ChainId.ZKSYNC]: 'zksync',
  [ChainId.ZKSYNC_TESTNET]: 'zksyncTestnet',
  [ChainId.DEV_ETH]: 'devEth',
  [ChainId.DEV_ZK]: 'devZk',
  [ChainId.POLYGON]: 'polygon',
  [ChainId.LINEA]: 'linea',
  [ChainId.FIL_TESTNET]: 'filTestnet',
  [ChainId.POLYGON_TESTNET]: 'polygonTestnet',
  [ChainId.LINEA_TESTNET]: 'lineaTestnet',
  [ChainId.FIL]: 'fil',
  [ChainId.BASE]: 'base',
} satisfies Record<ChainId, string>

const CHAIN_QUERY_NAME_TO_ID = invert(CHAIN_QUERY_NAME)

export const getChainId = memoize((chainName: string) => {
  if (!chainName) return undefined
  return CHAIN_QUERY_NAME_TO_ID[chainName] ? +CHAIN_QUERY_NAME_TO_ID[chainName] : undefined
})

export const zkChains = [ChainId.ZKSYNC, ChainId.ZKSYNC_TESTNET, ChainId.DEV_ZK]
