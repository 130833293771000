import { BigNumber } from '@ethersproject/bignumber'
import { Pool } from '@pancakeswap/uikit'
import { SerializedWrappedToken } from '@pancakeswap/token-lists'
import {
  PETA,
  USDC,
  PETA_LP_DEVETH,
  PETA_LP_DEVZK,
  PETA_USD_LP_DEVZK,
} from '@pancakeswap/tokens'
import { PoolCategory } from './types'
import { ChainId } from '@pancakeswap/sdk'
import { WETH9 } from '@pancakeswap/sdk/src'

export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 604800
export const ONE_WEEK_DEFAULT = 604800
export const BOOST_WEIGHT = BigNumber.from('20000000000000')
export const DURATION_FACTOR = BigNumber.from('31536000')

export const vaultPoolConfig = {} as const

export const livePools: Pool.SerializedPoolConfig<SerializedWrappedToken>[] = [
  {
    poolIndex: 3,
    sousId: 1,
    stakingToken: USDC[ChainId.DEV_ETH],
    earningToken: PETA[ChainId.DEV_ETH],
    contractAddress: {
      9: '0xFb5b65ecE7D264dEF13347031A58Be80F6BC33a3',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1',
    isFinished: false,
    isMiracle: false,
    tokenA: undefined,
    tokenB: undefined,
  },
  {
    sousId: 4,
    poolIndex: 4,
    stakingToken: PETA_LP_DEVETH,
    earningToken: PETA[ChainId.DEV_ETH],
    contractAddress: {
      9: '0xFb5b65ecE7D264dEF13347031A58Be80F6BC33a3',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2',
    isFinished: false,
    isMiracle: true,
    tokenA: PETA[ChainId.DEV_ETH],
    tokenB: WETH9[ChainId.DEV_ETH],
  },
  {
    sousId: 11,
    poolIndex: 10,
    stakingToken: PETA[ChainId.DEV_ZK],
    earningToken: PETA[ChainId.DEV_ZK],
    contractAddress: {
      270: '0x5880618D943c929ea939dcE4fEdc61284AaE58Cb',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10000',
    isFinished: false,
    isMiracle: false,
    tokenA: undefined,
    tokenB: undefined,
  },
  {
    sousId: 12,
    poolIndex: 11,
    stakingToken: PETA_LP_DEVZK,
    earningToken: PETA[ChainId.DEV_ZK],
    contractAddress: {
      270: '0x5880618D943c929ea939dcE4fEdc61284AaE58Cb',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10000',
    isFinished: false,
    isMiracle: true,
    tokenA: PETA[ChainId.DEV_ZK],
    tokenB: WETH9[ChainId.DEV_ZK],
  },
  {
    sousId: 13,
    poolIndex: 12,
    stakingToken: PETA_USD_LP_DEVZK,
    earningToken: PETA[ChainId.DEV_ZK],
    contractAddress: {
      270: '0x5880618D943c929ea939dcE4fEdc61284AaE58Cb',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10000',
    isFinished: false,
    isMiracle: true,
    tokenA: PETA[ChainId.DEV_ZK],
    tokenB: USDC[ChainId.DEV_ZK],
  },
  {
    sousId: 14,
    poolIndex: 2,
    stakingToken: PETA[ChainId.ZKSYNC_TESTNET],
    earningToken: PETA[ChainId.ZKSYNC_TESTNET],
    contractAddress: {
      280: '0x9e6D8Fdd02A662a9cfFd6D7310425a64bF31DD17',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10',
    isFinished: false,
    isMiracle: false,
    tokenA: undefined,
    tokenB: undefined,
  },
].map((p) => ({
  ...p,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
  tokenA: p.tokenA ? p.tokenA.serialize : undefined,
  tokenB: p.tokenB ? p.tokenB.serialize : undefined,
}))

// known finished pools
const finishedPools: Pool.SerializedPoolConfig<SerializedWrappedToken>[] = [
  {
    sousId: 5,
    poolIndex: 1,
    stakingToken: PETA_LP_DEVZK,
    earningToken: PETA[ChainId.DEV_ZK],
    contractAddress: {
      270: '0x5880618D943c929ea939dcE4fEdc61284AaE58Cb',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2',
    isFinished: false,
    isMiracle: true,
    tokenA: PETA[ChainId.DEV_ZK],
    tokenB: WETH9[ChainId.DEV_ZK],
  },
  {
    sousId: 9,
    poolIndex: 1,
    stakingToken: USDC[ChainId.DEV_ETH],
    earningToken: PETA[ChainId.DEV_ETH],
    contractAddress: {
      9: '0xFb5b65ecE7D264dEF13347031A58Be80F6BC33a3',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1',
    isFinished: true,
    isMiracle: false,
    tokenA: undefined,
    tokenB: undefined,
  },
  {
    sousId: 10,
    poolIndex: 2,
    stakingToken: PETA_LP_DEVETH,
    earningToken: PETA[ChainId.DEV_ETH],
    contractAddress: {
      9: '0xFb5b65ecE7D264dEF13347031A58Be80F6BC33a3',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10',
    isMiracle: true,
    tokenA: PETA[ChainId.DEV_ETH],
    tokenB: WETH9[ChainId.DEV_ETH],
  },
  {
    sousId: 2,
    poolIndex: 0,
    stakingToken: USDC[ChainId.DEV_ZK],
    earningToken: PETA[ChainId.DEV_ZK],
    contractAddress: {
      270: '0x5880618D943c929ea939dcE4fEdc61284AaE58Cb',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2',
    isFinished: true,
    isMiracle: false,
    tokenA: undefined,
    tokenB: undefined,
  },
  {
    sousId: 3,
    poolIndex: 3,
    stakingToken: USDC[ChainId.DEV_ZK],
    earningToken: PETA[ChainId.DEV_ZK],
    contractAddress: {
      270: '0x5880618D943c929ea939dcE4fEdc61284AaE58Cb',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2',
    isFinished: true,
    isMiracle: false,
    tokenA: undefined,
    tokenB: undefined,
  },
  {
    sousId: 6,
    poolIndex: 8,
    stakingToken: PETA_LP_DEVZK,
    earningToken: PETA[ChainId.DEV_ZK],
    contractAddress: {
      270: '0x5880618D943c929ea939dcE4fEdc61284AaE58Cb',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10000',
    isFinished: true,
    isMiracle: true,
    tokenA: PETA[ChainId.DEV_ZK],
    tokenB: WETH9[ChainId.DEV_ZK],
  },
  {
    sousId: 7,
    poolIndex: 5,
    stakingToken: PETA[ChainId.DEV_ZK],
    earningToken: PETA[ChainId.DEV_ZK],
    contractAddress: {
      270: '0x5880618D943c929ea939dcE4fEdc61284AaE58Cb',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10000',
    isFinished: true,
    isMiracle: false,
    tokenA: undefined,
    tokenB: undefined,
  },
  {
    sousId: 8,
    poolIndex: 9,
    stakingToken: PETA_USD_LP_DEVZK,
    earningToken: PETA[ChainId.DEV_ZK],
    contractAddress: {
      270: '0x5880618D943c929ea939dcE4fEdc61284AaE58Cb',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10000',
    isFinished: true,
    isMiracle: true,
    tokenA: PETA[ChainId.DEV_ZK],
    tokenB: USDC[ChainId.DEV_ZK],
  },
].map((p) => ({
  ...p,
  isFinished: true,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
  tokenA: p.tokenA ? p.tokenA.serialize : undefined,
  tokenB: p.tokenB ? p.tokenB.serialize : undefined,
}))
export default [...livePools, ...finishedPools] as Pool.SerializedPoolConfig<SerializedWrappedToken>[]
