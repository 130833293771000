import { ChainId } from '@pancakeswap/sdk'

export const SUPPORT_ONLY_BSC = [
  ChainId.ZKSYNC,
  ChainId.ZKSYNC_TESTNET,
  ChainId.DEV_ETH,
  ChainId.DEV_ZK,
  ChainId.POLYGON,
  ChainId.POLYGON_TESTNET,
  ChainId.BSC_TESTNET,
  ChainId.BSC,
  ChainId.FIL_TESTNET,
  ChainId.LINEA_TESTNET,
  ChainId.ETHEREUM,
  ChainId.GOERLI,
  ChainId.BASE,
]
export const SUPPORT_FARMS = [ ChainId.ZKSYNC,ChainId.ZKSYNC_TESTNET, ChainId.DEV_ETH, ChainId.DEV_ZK, ChainId.POLYGON, ChainId.POLYGON_TESTNET, ChainId.BSC_TESTNET, ChainId.BSC, ChainId.FIL_TESTNET, ChainId.LINEA_TESTNET, ChainId.ETHEREUM, ChainId.GOERLI, ChainId.BASE]

export const SUPPORT_ZAP = [] //ChainId.BSC, ChainId.BSC_TESTNET
