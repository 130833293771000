import { ChainId, ERC20Token } from '@pancakeswap/sdk'
import {  WrappedTokenInfo } from '@pancakeswap/token-lists'

export const PETA_LP_DEVETH = new WrappedTokenInfo({
  chainId: ChainId.DEV_ETH,
  address: '0x48563d004340Ca2F9aCe6ed5d94D6D29662A0a64',
  decimals: 18,
  symbol: 'PETA-ETH LP',
  name: 'Petaverse LP',
  logoURI: '/images/tokens/0x48563d004340Ca2F9aCe6ed5d94D6D29662A0a64.png',
  extensions: {
    tokenAddress: '0x90CDcfCeF6698cB67a484CE17ede7E19e349711D',
    quoteTokenAddress: '0x8f51A039A642BE20243ca68aC30B13419FBfDBf5',
  },
})

export const PETA_LP_DEVZK = new WrappedTokenInfo({
  chainId: ChainId.DEV_ZK,
  address: '0xfDD4E194fBcb2Afa1Dbb57d0344F67470173c148',
  decimals: 18,
  symbol: 'PPTS-ETH LP',
  name: 'Petaverse LP',
  logoURI: '/images/tokens/0xfDD4E194fBcb2Afa1Dbb57d0344F67470173c148.png',
  extensions: {
    tokenAddress: '0x946DF1F3B9ad6b38FC46189F2b8547842c1b5D9b',
    quoteTokenAddress: '0xD40904E622ca88295427e6e5FF4a65C621C7F5dF',
  },
})

export const PETA_USD_LP_DEVZK = new WrappedTokenInfo({
  chainId: ChainId.DEV_ZK,
  address: '0xb84426502a556CE9Eaae9B54D76399E3d61a621B',
  decimals: 18,
  symbol: 'PPTS-USDC LP',
  name: 'Petaverse LP',
  // logoURI: '/images/tokens/.png',
  extensions: {
    tokenAddress: '0x946DF1F3B9ad6b38FC46189F2b8547842c1b5D9b',
    quoteTokenAddress: '0x17E33de4824D09547541f9786C4038B59dFF5bEA',
  },
})

export const PETA_GOERLI = new WrappedTokenInfo({
  chainId: ChainId.GOERLI,
  address: '0x8693cB80103C844E970dB8Ee32EAd50174561aE5',
  decimals: 18,
  symbol: 'PPT',
  name: 'Petaverse Token',
  logoURI: '/images/tokens/0x8693cB80103C844E970dB8Ee32EAd50174561aE5.png',
  extensions: {
    projectLink: process.env.NEXT_PUBLIC_PETA_WEB_DOMAIN,
  },
})

export const TEST_GOERLI = new WrappedTokenInfo({
  chainId: ChainId.GOERLI,
  address: '0xd480bDB3d048dcf514b723B2d760cB3BdF5E2B2F',
  decimals: 18,
  symbol: 'TEST',
  name: 'TEST',
  logoURI: '/images/tokens/0xd480bDB3d048dcf514b723B2d760cB3BdF5E2B2F.png',
  extensions: {
    projectLink: process.env.NEXT_PUBLIC_PETA_WEB_DOMAIN,
  },
})

export const PETA_DEV_ETH = new WrappedTokenInfo({
  chainId: ChainId.DEV_ETH,
  address: '0x90CDcfCeF6698cB67a484CE17ede7E19e349711D',
  decimals: 18,
  symbol: 'TEST',
  name: 'Petaverse Token',
  logoURI: '/images/tokens/0x90CDcfCeF6698cB67a484CE17ede7E19e349711D.png',
  extensions: {
    projectLink: process.env.NEXT_PUBLIC_PETA_WEB_DOMAIN,
  },
})
/*  new ERC20Token(
  ChainId.DEV_ETH,
  '0x90CDcfCeF6698cB67a484CE17ede7E19e349711D',
  18,
  'TEST',
  'Petaverse Token',
  process.env.NEXT_PUBLIC_PETA_WEB_DOMAIN,
)*/

export const PETA_POLYGON = new WrappedTokenInfo({
  chainId: ChainId.POLYGON,
  address: '0x4d752B9ABB29e7be38f54D2106745395CdCe9965',
  decimals: 18,
  symbol: 'PPT',
  name: 'PawPoints',
  logoURI: '/images/tokens/0x4d752B9ABB29e7be38f54D2106745395CdCe9965.png',
})

export const PETA_BSC = new WrappedTokenInfo({
  chainId: ChainId.BSC,
  address: '0x368389105496fe6522143D93bAa9D71E0278B94B',
  decimals: 18,
  symbol: 'PPT',
  name: 'PawPoints',
  logoURI: '/images/tokens/0x368389105496fe6522143D93bAa9D71E0278B94B.png',
})

export const PETA_POLYGON_TESTNET = new WrappedTokenInfo({
  chainId: ChainId.POLYGON_TESTNET,
  address: '0x73C57d19aFC23ce55c4Cb9b3f6f41D95C6d8D24d',
  decimals: 18,
  symbol: 'PPT',
  name: 'PawPoints',
  logoURI: '/images/tokens/0x73C57d19aFC23ce55c4Cb9b3f6f41D95C6d8D24d.png',
})

export const PETA_FIL_TESTNET = new WrappedTokenInfo({
  chainId: ChainId.FIL_TESTNET,
  address: '0x2BEcd2Aca533acAc211e19eFbb4941c23f29d644',
  decimals: 18,
  symbol: 'PPT',
  name: 'PawPoints',
  logoURI: '/images/tokens/0x2BEcd2Aca533acAc211e19eFbb4941c23f29d644.png',
})

export const PETA_LINEA_TESTNET = new WrappedTokenInfo({
  chainId: ChainId.LINEA_TESTNET,
  address: '0x9F96DDf65E60cCD49B73340d8bd75448f33709DC',
  decimals: 18,
  symbol: 'PPT',
  name: 'PawPoints',
  logoURI: '/images/tokens/0x9F96DDf65E60cCD49B73340d8bd75448f33709DC.png',
})

export const PETA_LINEA = new WrappedTokenInfo({
  chainId: ChainId.LINEA,
  address: '0x0000000000000000000000000000000000000000',
  decimals: 18,
  symbol: 'PPT',
  name: 'PawPoints',
  logoURI: '/images/tokens/0x0000000000000000000000000000000000000000.png',
})

export const PETA_FIL = new WrappedTokenInfo({
  chainId: ChainId.FIL,
  address: '0x529F6Ca059b3f65bd93D872D038F0D5052eA3c81',
  decimals: 18,
  symbol: 'PPT',
  name: 'PawPoints',
  logoURI: '/images/tokens/0x529F6Ca059b3f65bd93D872D038F0D5052eA3c81.png',
})

export const TEST_POLYGON_TESTNET = new WrappedTokenInfo({
  chainId: ChainId.POLYGON_TESTNET,
  address: '0x3127633ea511681915697a26094E27Ad1E141179',
  decimals: 18,
  symbol: 'TEST',
  name: 'TEST',
  logoURI: '/images/tokens/TEST.png',
})

export const CBS_POLYGON = new WrappedTokenInfo({
  chainId: ChainId.POLYGON,
  address: '0x7377a74973c2afdd1c94a08518d5a3b73077fa78',
  decimals: 18,
  symbol: 'CBS',
  name: 'CBS',
  logoURI: '/images/tokens/0x7377a74973c2afdd1c94a08518d5a3b73077fa78.png',
})

export const CITD_ZKSYNC_TESTNET = new WrappedTokenInfo({
  chainId: ChainId.ZKSYNC_TESTNET,
  address: '0x3687623816ee5C17A3c82e18DE6D8910Bf2791aB',
  decimals: 18,
  symbol: 'CITD',
  name: 'CITD',
  logoURI: '/images/tokens/0x3687623816ee5C17A3c82e18DE6D8910Bf2791aB.png',
})

export const CBS_POLYGON_TESTNET = new WrappedTokenInfo({
  chainId: ChainId.POLYGON_TESTNET,
  address: '0xEE008Ecfb877B7Eb04d9F3fF705a2302e4A0a288',
  decimals: 18,
  symbol: 'CBS',
  name: 'CBS',
  logoURI: '/images/tokens/0xEE008Ecfb877B7Eb04d9F3fF705a2302e4A0a288.png',
})

export const EBS_POLYGON_TESTNET = new WrappedTokenInfo({
  chainId: ChainId.POLYGON_TESTNET,
  address: '0xE6C06FA739eef3BF198E6e9A9eF2408e22F401cE',
  decimals: 18,
  symbol: 'EBS',
  name: 'EBS',
  logoURI: '/images/tokens/0xE6C06FA739eef3BF198E6e9A9eF2408e22F401cE.png',
})

export const RewardTokens = ['0xBDE7f6e8c5d1BC3A3F023Bf120472a515ed5D78e']
export const SecurityTokens = ['0x212130CBBa98a699571fd628151108aF4A34008C']
export const RewardLink = 'https://getRewardSbtLink.com'
export const SecurityLink = 'https://getSecuritySbtLink.com'

export const Security_POLYGON_TESTNET = new WrappedTokenInfo({
  chainId: ChainId.POLYGON_TESTNET,
  address: '0x212130CBBa98a699571fd628151108aF4A34008C',
  decimals: 18,
  symbol: 'Security',
  name: '3339 Security Token',
  logoURI: '/images/tokens/0x212130CBBa98a699571fd628151108aF4A34008C.png',
})

// interface Erc3339TokenInfo extends WrappedTokenInfo{
  // type:'reward'|'security'
  // constructor(tokenInfo: TokenInfo,tokenType:'reward'|'security')
// }

export const Reward_POLYGON_TESTNET = new WrappedTokenInfo({
  chainId: ChainId.POLYGON_TESTNET,
  address: '0xBDE7f6e8c5d1BC3A3F023Bf120472a515ed5D78e',
  decimals: 18,
  symbol: 'Reward',
  name: '3339 Reward Token',
  logoURI: '/images/tokens/0xBDE7f6e8c5d1BC3A3F023Bf120472a515ed5D78e.png',
})

export const HKDT_POLYGON_TESTNET = new WrappedTokenInfo({
  chainId: ChainId.POLYGON_TESTNET,
  address: '0x413B51A7d022657b96C0684e693F28F610D6Fbd3',
  decimals: 18,
  symbol: 'HKDT',
  name: 'HKDT',
  logoURI: '/images/tokens/0x413B51A7d022657b96C0684e693F28F610D6Fbd3.png',
})

export const PETA_DEV_ZK = new WrappedTokenInfo({
  chainId: ChainId.DEV_ZK,
  address: '0x946DF1F3B9ad6b38FC46189F2b8547842c1b5D9b',
  decimals: 18,
  symbol: 'PPT',
  name: 'Pawpoints',
  logoURI: '/images/tokens/0x946DF1F3B9ad6b38FC46189F2b8547842c1b5D9b.png',
  extensions: {
    projectLink: process.env.NEXT_PUBLIC_PETA_WEB_DOMAIN,
  },
})

export const PETA_BSC_TESTNET = new WrappedTokenInfo({
  chainId: ChainId.BSC_TESTNET,
  address: '0x4d827FB8252B616dF178bA043D89a4B5DA6E33D3',
  decimals: 18,
  symbol: 'PPT',
  name: 'Pawpoints',
  logoURI: '/images/tokens/0x4d827FB8252B616dF178bA043D89a4B5DA6E33D3.png',
  extensions: {
    projectLink: process.env.NEXT_PUBLIC_PETA_WEB_DOMAIN,
  },
})

export const PETA_ETHEREUM = new WrappedTokenInfo({
  chainId: ChainId.ETHEREUM,
  address: '0xd3a60c303d647eaf04554599bb8da5cc18aa3523',
  decimals: 18,
  symbol: 'PPT',
  name: 'Pawpoints',
  logoURI: '/images/tokens/0xd3a60c303d647eaf04554599bb8da5cc18aa3523.png',
  extensions: {
    projectLink: process.env.NEXT_PUBLIC_PETA_WEB_DOMAIN,
  },
})

export const PETA_ZKSYNC_TESTNET = new WrappedTokenInfo({
  chainId: ChainId.ZKSYNC_TESTNET,
  address: '0x5666692e82e983C33065D3CBC3dfC8D1602D6F1e',
  decimals: 18,
  symbol: 'PPT',
  name: 'Petaverse Token',
  logoURI: '/images/tokens/0x5666692e82e983C33065D3CBC3dfC8D1602D6F1e.png',
  extensions: {
    projectLink: process.env.NEXT_PUBLIC_PETA_WEB_DOMAIN,
  },
})

export const TEST_ZKSYNC_TESTNET = new WrappedTokenInfo({
  chainId: ChainId.ZKSYNC_TESTNET,
  address: '0xEbb2F532220CA01f929e51b6f5346f0c7A58Cd27',
  decimals: 18,
  symbol: 'TEST',
  name: 'TEST',
  logoURI: '/images/tokens/0xEbb2F532220CA01f929e51b6f5346f0c7A58Cd27.png',
  extensions: {
    projectLink: process.env.NEXT_PUBLIC_PETA_WEB_DOMAIN,
  },
})

export const PETA_ZKSYNC = new WrappedTokenInfo({
  chainId: ChainId.ZKSYNC,
  address: '0x030B8487c5f5b77193b53e56F951865B79358e30',
  decimals: 18,
  symbol: 'PPT',
  name: 'Pawpoints',
  logoURI: '/images/tokens/0x030B8487c5f5b77193b53e56F951865B79358e30.png',
  extensions: {
    projectLink: process.env.NEXT_PUBLIC_PETA_WEB_DOMAIN,
  },
})

export const PETA_BASE = new WrappedTokenInfo({
  chainId: ChainId.BASE,
  address: '0x331b0FA79d71d5A6166CE590b95C71bFB0F416F4',
  decimals: 18,
  symbol: 'PPT',
  name: 'Pawpoints',
  logoURI: '/images/tokens/0x331b0FA79d71d5A6166CE590b95C71bFB0F416F4.png',
  extensions: {
    projectLink: process.env.NEXT_PUBLIC_PETA_WEB_DOMAIN,
  },
})

export const CAKE_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  18,
  'CAKE',
  'PancakeSwap Token',
  'https://pancakeswap.finance/',
)

export const CAKE_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xFa60D973F7642B748046464e165A65B7323b0DEE',
  18,
  'CAKE',
  'PancakeSwap Token',
  'https://pancakeswap.finance/',
)

export const USDC_BSC = new ERC20Token(
  ChainId.BSC,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_BASE = new ERC20Token(
  ChainId.BASE,
  '0x0000000000000000000000000000000000000000',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0x64544969ed7EBf5f083679233325356EbE738930',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  6,
  'USDC',
  'USD Coin',
)

export const USDC_LINEA = new ERC20Token(
  ChainId.LINEA,
  '0x0000000000000000000000000000000000000000',
  6,
  'USDC',
  'USD Coin',
)

export const USDC_FIL = new ERC20Token(
  ChainId.FIL,
  '0x0000000000000000000000000000000000000000',
  6,
  'USDC',
  'USD Coin',
)

export const USDC_ZKSYNC = new ERC20Token(
  ChainId.ZKSYNC,
  '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
  6,
  'USDC',
  'USD Coin',
)

export const USDC_POLYGON = new ERC20Token(
  ChainId.POLYGON,
  '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  6,
  'USDC',
  'USD Coin',
)

export const USDC_FIL_TESTNET = new ERC20Token(
  ChainId.FIL_TESTNET,
  '0xe7670E07285505A16dfDE095964aA0C3ac64AB2f',
  18,
  'USDC',
  'USD Coin',
)

export const USDC_LINEA_TESTNET = new ERC20Token(
  ChainId.LINEA_TESTNET,
  '0xf56dc6695cF1f5c364eDEbC7Dc7077ac9B586068',
  18,
  'USDC',
  'USD Coin',
)

export const USDC_POLYGON_TESTNET = new ERC20Token(
  ChainId.POLYGON_TESTNET,
  '0x21b5Debd203eb766Bbce27c095A265A57b9Ec3e1',
  18,
  'USDC',
  'USD Coin',
)

export const USDC_ZKSYNC_TESTNET = new WrappedTokenInfo({
  chainId: ChainId.ZKSYNC_TESTNET,
  address: '0x0faF6df7054946141266420b43783387A78d82A9',
  decimals: 6,
  symbol: 'tUSDC',
  name: 'test USD Coin',
  logoURI: 'https://tokens.pancakeswap.finance/images/symbol/usdc.png',
  extensions: {
    projectLink: 'https://tether.to/',
  },
})

export const USDC_GOERLI = new WrappedTokenInfo({
  chainId: ChainId.GOERLI,
  address: '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
  decimals: 6,
  symbol: 'tUSDC',
  name: 'test USD Coin',
  logoURI: 'https://tokens.pancakeswap.finance/images/symbol/usdc.png',
  extensions: {
    projectLink: 'https://tether.to/',
  },
})

export const USDC_DEV_ETH = new WrappedTokenInfo({
  chainId: ChainId.DEV_ETH,
  address: '0x98233f04bC2957E701d89a359D6e7F42b6dC3059',
  decimals: 6,
  symbol: 'tUSDC',
  name: 'test USD Coin',
  logoURI: 'https://tokens.pancakeswap.finance/images/symbol/usdc.png',
  extensions: {
    projectLink: 'https://tether.to/',
  },
})

export const USDC_DEV_ZK = new WrappedTokenInfo({
  chainId: ChainId.DEV_ZK,
  address: '0x17E33de4824D09547541f9786C4038B59dFF5bEA',
  decimals: 6,
  symbol: 'tUSDC',
  name: 'test USD Coin',
  logoURI: 'https://tokens.pancakeswap.finance/images/symbol/usdc.png',
  extensions: {
    projectLink: 'https://tether.to/',
  },
})

export const USDT_BSC = new ERC20Token(
  ChainId.BSC,
  '0x55d398326f99059fF775485246999027B3197955',
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_POLYGON = new ERC20Token(
  ChainId.POLYGON,
  '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  6,
  'USDT',
  'Tether USD',
  'https://mapper.polygon.technology/',
)

export const USDT_DEV_ETH = new ERC20Token(
  ChainId.DEV_ETH,
  '0x5283263f62d866c6B3571A32aF9CD8c28ab804Ab',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const BUSD_BSC = new ERC20Token(
  ChainId.BSC,
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_GOERLI = new ERC20Token(
  ChainId.GOERLI,
  '0xb809b9B2dc5e93CB863176Ea2D565425B03c0540',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD = {
  [ChainId.ETHEREUM]: BUSD_ETH,
  [ChainId.GOERLI]: BUSD_GOERLI,
  [ChainId.BSC]: BUSD_BSC,
  [ChainId.BSC_TESTNET]: BUSD_TESTNET,
}

export const PETA = {
  [ChainId.ETHEREUM]: PETA_ETHEREUM,
  [ChainId.GOERLI]: PETA_GOERLI,
  [ChainId.ZKSYNC]: PETA_ZKSYNC,
  [ChainId.ZKSYNC_TESTNET]: PETA_ZKSYNC_TESTNET,
  [ChainId.DEV_ETH]: PETA_DEV_ETH,
  [ChainId.DEV_ZK]: PETA_DEV_ZK,
  [ChainId.POLYGON]: CBS_POLYGON,
  [ChainId.POLYGON_TESTNET]: Reward_POLYGON_TESTNET,
  [ChainId.BSC_TESTNET]: PETA_BSC_TESTNET,
  [ChainId.BSC]: PETA_BSC,
  [ChainId.FIL_TESTNET]: PETA_FIL_TESTNET,
  [ChainId.LINEA_TESTNET]: PETA_LINEA_TESTNET,
  [ChainId.FIL]: PETA_FIL,
  [ChainId.LINEA]: PETA_LINEA,
  // [ChainId.BASE]: PETA_BASE,
}

export const CAKE = {
  [ChainId.BSC]: CAKE_MAINNET,
  [ChainId.BSC_TESTNET]: CAKE_TESTNET,
}

export const USDC = {
  [ChainId.BSC]: USDC_BSC,
  [ChainId.BSC_TESTNET]: USDC_TESTNET,
  [ChainId.ETHEREUM]: USDC_ETH,
  [ChainId.GOERLI]: USDC_GOERLI,
  [ChainId.ZKSYNC]: USDC_ZKSYNC,
  [ChainId.ZKSYNC_TESTNET]: USDC_ZKSYNC_TESTNET,
  [ChainId.DEV_ETH]: USDC_DEV_ETH,
  [ChainId.DEV_ZK]: USDC_DEV_ZK,
  [ChainId.POLYGON_TESTNET]: USDC_POLYGON_TESTNET,
  [ChainId.POLYGON]: USDC_POLYGON,
  [ChainId.FIL_TESTNET]: USDC_FIL_TESTNET,
  [ChainId.LINEA_TESTNET]: USDC_LINEA_TESTNET,
  [ChainId.FIL]: USDC_FIL,
  [ChainId.LINEA]: USDC_LINEA,
  [ChainId.BASE]: USDC_BASE,
}

export const USDT = {
  [ChainId.BSC]: USDT_BSC,
  [ChainId.ETHEREUM]: USDT_ETH,
  [ChainId.DEV_ETH]: USDT_DEV_ETH,
}

export const WBTC_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  8,
  'WBTC',
  'Wrapped BTC',
)
